import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import { H1 } from '../../../../common/components/texts';
import { Service, ServiceCategory } from '../../../../root.types';
import { deselectService, selectService } from '../../forces/actions';
import { BookingSectionIDs } from '../../forces/types';
import { getFetchServicesError, getServiceCategories, getServices } from './forces/selectors';
import styles from './ServiceSection.module.css';
import { trackBookingStep } from '../../../../common/analytics/posthog';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { useEffect, useMemo } from 'react';
import { queryStringToArray } from '../../../../common/utils/url.utils';
import ServiceListNew from './components/ServiceList';

interface Props {
  bookingId?: string;
}

const ServiceSection = ({ bookingId }: Props) => {
  const serviceCategories = useSelector(getServiceCategories);
  const query = useLocation().search;
  const { serviceId: serviceFromUrlParam } = parse(query);
  const error = useSelector(getFetchServicesError);
  const hideOnUpdateMode = bookingId;
  const services = useSelector(getServices);
  const sortedServiceCategories =
    serviceCategories && serviceCategories.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  const dispatch = useDispatch();

  if (!serviceCategories || hideOnUpdateMode) return null;

  const onSelectService = (service: Service) => {
    dispatch(selectService(service));
    trackBookingStep(`${service.isChild ? 'Velg underbehandling' : 'Velg behandling'}`, {
      serviceId: service.waitwhileServiceId,
      serviceName: service.displayTitle,
      isPackageDeal: service.isPackageDeal,
      isRequiredAdditionalService: service.isRequiredAdditionalService,
    });
    if (service.isChild) return;
    // Check and select required additionalServices
    const additionalServices = service.additionalServices;
    const hasRequiredAdditionalServices =
      additionalServices && additionalServices.some((s) => s.isRequiredAdditionalService);
    if (!hasRequiredAdditionalServices) return;

    const defaultRequiredAdditionalService = additionalServices.find(
      (s) => s.id === service.defaultRequiredAdditionalServiceId
    );
    if (defaultRequiredAdditionalService) {
      onSelectService(defaultRequiredAdditionalService);
    } else {
      const serviceIndex = additionalServices.length > 1 ? 1 : 0;
      onSelectService(additionalServices[serviceIndex]);
    }
  };

  useEffect(() => {
    if (!serviceFromUrlParam) return;
    services.forEach((service) => {
      if (queryStringToArray(serviceFromUrlParam).includes(service.waitwhileServiceId)) {
        onSelectService(service);
      }
    });
  }, []);

  const serviceCategoryToOpen = useMemo(() => {
    return null; // Don't open any category by default
    const defaultCategoryToOpen = [sortedServiceCategories[0].id];
    // TODO: We could auto-open the category, the same way as service above, if we had the WebflowId in the Webflow CMS
    // https://discourse.webflow.com/t/getting-dynamic-cms-item-id-inside-a-form-embed/175702/5
    // if (serviceCategoryFromUrlParam) {
    //   const selectedServiceCategory = sortedServiceCategories.find(
    //     (serviceCategory) => serviceCategory.id === serviceCategoryFromUrlParam
    //   );
    //   if (selectedServiceCategory) return [selectedServiceCategory.id];
    // }

    if (!serviceFromUrlParam) return defaultCategoryToOpen;
    const selectedServices = services.filter((service) =>
      queryStringToArray(serviceFromUrlParam).includes(service.waitwhileServiceId)
    );
    return selectedServices.length
      ? selectedServices.map((service) => service.serviceCategory?.id)
      : defaultCategoryToOpen;
  }, [query]);

  return (
    <Scroll.Element name={BookingSectionIDs.ServiceSelection}>
      <section className={styles.container}>
        <H1 className={styles.header}>Velg behandling</H1>
        {sortedServiceCategories.map((serviceCategory: ServiceCategory, index: number) => {
          if (serviceCategory.hidden) return null;

          return (
            <ServiceListNew
              key={serviceCategory.id}
              serviceCategory={serviceCategory}
              handleSelection={onSelectService}
              handleDeselection={(service) => dispatch(deselectService(service))}
              isOpened={
                serviceCategoryToOpen ? serviceCategoryToOpen.includes(serviceCategory.id) : false
              }
            />
          );
        })}
      </section>
    </Scroll.Element>
  );
};

export default ServiceSection;
