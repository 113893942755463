import { AvailabilityPicker } from '@askeladden/availability-ui';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import CallToActionButton from '../../../../common/components/callToAction/CallToActionButton';
import LoadingIndicator from '../../../../common/components/loadingIndicator/LoadingIndicator';
import { BodyText, H2, H3 } from '../../../../common/components/texts';
import OtherBookableClinicsSuggestion from '../../components/OtherBookableClinicsSuggestion';
import {
  changeCalendarRange,
  resetBookingError,
  selectDate,
  selectSlot,
  selectTime,
  validateAndScrollBookingSections,
} from '../../forces/actions';
import {
  getSelectedClinic,
  getSelectedDate,
  getSelectedResource,
  getSelectedServices,
} from '../../forces/selectors';
import { BookingSectionIDs } from '../../forces/types';
import styles from './CalendarSection.module.css';
import DayPicker from './components/DayPicker';
import TimePicker from './components/TimePicker';
import { fetchAvailability } from './forces/actions';
import {
  getAvailableDatesDirect,
  getError,
  getLoading,
  isAdditionalAvailableDatesLoading,
} from './forces/selectors';
import { AcoBookingLocationAvailabilityErrorCode, AvailableDate, Slot } from './forces/types';
import { appConfig } from '../../../../config';
import { trackBookingStep } from '../../../../common/analytics/posthog';

const NO_AVAILABLE_FULLY_BOOKED_TEXT =
  'Olio er fullbooket denne datoen. Vennligst velg en annen dato for å booke time';
const CHOOSE_SERVICES_AND_LOCATION_FIRST_TEXT =
  'Velg behandling og lokasjon først for å kunne velge dato';
const SELECTED_RESOURCE_HAS_NO_AVAILABLE_DATE =
  'Valgte behandleren har ingen ledige timer for denne behandlingen. Vennligst velg en annen behandler';

type Props = {
  defaultSelectFirstAvailable?: boolean;
};

const CalendarSection = ({ defaultSelectFirstAvailable = true }: Props) => {
  const isLoading = useSelector(getLoading);
  const isAdditionalDaysLoading = useSelector(isAdditionalAvailableDatesLoading);
  const error = useSelector(getError);
  const selectedServices = useSelector(getSelectedServices);
  const selectedClinic = useSelector(getSelectedClinic);
  const selectedDate = useSelector(getSelectedDate);
  const selectedResource = useSelector(getSelectedResource);
  const availableDatesDirect = useSelector(getAvailableDatesDirect);
  const hasSelectedServicesAndClinic = selectedServices && selectedClinic;
  const hasAvailableSlots =
    selectedClinic &&
    availableDatesDirect.some((date) =>
      date.slots.some((slot: Slot) => slot.numberOfAvailableSlots > 0)
    );
  const selectedDayisAvailable = availableDatesDirect.find(
    (date) => date.date === selectedDate
  )?.isAvailable;
  const showOtherBookableClinicsSuggestion =
    !hasAvailableSlots &&
    hasSelectedServicesAndClinic &&
    !isLoading &&
    availableDatesDirect.length > 0;
  const showCalendar = hasSelectedServicesAndClinic && hasAvailableSlots;
  const dispatch = useDispatch();

  const handleDateSelection = (date: AvailableDate) => {
    dispatch(resetBookingError());
    dispatch(selectDate(date.date));
    dispatch(validateAndScrollBookingSections());
    trackBookingStep('Velg dato', { date: date.date, slots: date.slots });
  };

  const handleTimeSlotSelection = (timeSlot: Slot) => {
    dispatch(resetBookingError());
    dispatch(selectTime(timeSlot.time));
    dispatch(selectSlot(timeSlot));
    dispatch(validateAndScrollBookingSections());
    trackBookingStep('Velg tid', { time: timeSlot.time });
  };
  const NUM_ADDITIONAL_DAYS = 14;
  const showLoader = isLoading && !isAdditionalDaysLoading && hasSelectedServicesAndClinic;

  const getAdditionalDays = (numberOfAdditionalDays: number) => {
    const lastDateInCurrentAvailableDates = availableDatesDirect.slice(-1)[0].date;
    const currentToDate = dayjs(lastDateInCurrentAvailableDates, { utc: true });
    const retrieveFrom = currentToDate.add(1, 'day').toISOString();
    const retrieveTo = dayjs(currentToDate).add(numberOfAdditionalDays, 'day').toISOString();
    dispatch(changeCalendarRange({ from: retrieveFrom, to: retrieveTo }));
    dispatch(fetchAvailability(true));
  };

  const renderSection = () => {
    if (showLoader)
      return (
        <div className={styles.loadingContainer}>
          <LoadingIndicator loadingText="Laster ledige timer" className={styles.loadingIndicator} />
        </div>
      );
    if (error) return renderErrorMessage(error);
    if (!hasSelectedServicesAndClinic)
      return (
        <BodyText className={styles.bodyText}>{CHOOSE_SERVICES_AND_LOCATION_FIRST_TEXT}</BodyText>
      );
    if (!hasAvailableSlots && selectedResource)
      return (
        <BodyText className={styles.bodyText}>{SELECTED_RESOURCE_HAS_NO_AVAILABLE_DATE}</BodyText>
      );
    if (showOtherBookableClinicsSuggestion) return <OtherBookableClinicsSuggestion />;
    if (showCalendar) {
      return (
        <>
          <div className={styles.horizontalFlex}>
            <BodyText className={styles.additionalText}>
              Ønsker du å booke lengre frem i tid?
            </BodyText>
            {isAdditionalDaysLoading ? (
              <div className={styles.additionalButtonWrapper}>
                <LoadingIndicator className={styles.additionalLoader} />
              </div>
            ) : (
              <CallToActionButton
                onClick={() => getAdditionalDays(NUM_ADDITIONAL_DAYS)}
                styleType="outlined"
                className={styles.additionalButtonWrapper}
              >
                {`Hent ${NUM_ADDITIONAL_DAYS} dager til`}
              </CallToActionButton>
            )}
          </div>
          <AvailabilityPicker
            // timeZone="Europe/Oslo"
            data={availableDatesDirect}
            dayComponent={DayPicker}
            onDaySelected={(d) => handleDateSelection(d)}
            labelComponent={(label) => (
              <div className={styles.header}>
                <H3 className={styles.title}>{label}</H3>
              </div>
            )}
            timeSlotComponent={TimePicker}
            onTimeSlotSelected={(t) => handleTimeSlotSelection(t)}
            groupWrapperClassName={styles.timeSection}
            slotWrapperClassName={styles.timeSlotList}
            dayWrapperClassName={styles.dayWrapper}
            defaultSelectFirstAvailable={defaultSelectFirstAvailable}
          />
          {!selectedDayisAvailable && (
            <div className={styles.noAvailableSlots}>
              <BodyText className={styles.bodyText}>{NO_AVAILABLE_FULLY_BOOKED_TEXT}</BodyText>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <Scroll.Element name={BookingSectionIDs.CalendarSection}>
      <section className={styles.container}>
        <H2 className={styles.title}>Dato og tid</H2>
        <div className={styles.divider} />
        {renderSection()}
      </section>
    </Scroll.Element>
  );
};

const renderErrorMessage = (error: any) => {
  if (
    (error instanceof Error &&
      error.message.includes(AcoBookingLocationAvailabilityErrorCode.ResourcesNotFound)) ||
    error.message.includes(AcoBookingLocationAvailabilityErrorCode.ResourceTypesNotFound) ||
    error.message.includes(AcoBookingLocationAvailabilityErrorCode.ResourcesNotCapable)
  ) {
    return (
      <BodyText className={styles.bodyText}>
        Vi fant ingen ledige timer for denne behandlingen på gitt lokasjon. Prøv å del opp bookingen
        eller ta kontakt med oss{' '}
        <a style={{ color: '#8e4f59' }} href={`${appConfig.typeform.url}`}>
          her
        </a>
      </BodyText>
    );
  }
  return (
    <BodyText className={styles.bodyText}>
      Oops! Her oppsto det en feil. Vennligst ta kontakt med oss{' '}
      <a style={{ color: '#8e4f59' }} href={`${appConfig.typeform.url}`}>
        her
      </a>{' '}
      for å booke time
    </BodyText>
  );
};

export default CalendarSection;
