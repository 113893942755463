import { useSelector } from 'react-redux';
import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { BodyBolderText } from '../../../../../common/components/texts';
import { formatToNOK, getNewPriceWithPoints } from '../../../../../common/utils/currency.utils';
import { Service } from '../../../../../root.types';
import { getCurrentUser } from '../../../../user/forces/selectors';
import {
  getBookingContext,
  getTotalPrice,
  getTotalPriceWithoutDynamicDiscount,
  getUseOlioPoints,
} from '../../../forces/selectors';
import { BookingContext } from '../../../forces/types';
import styles from '../SummarySection.module.css';
import { useOlioPoints } from '../../../../../common/hooks/useOlioPoints';

interface Props {
  services: Service[];
  appliedDiscountPercentage?: number;
}

const TotalPrice = ({ services, appliedDiscountPercentage = 0 }: Props) => {
  const { data } = useOlioPoints();
  const totalPrice = useSelector(getTotalPrice)(services, appliedDiscountPercentage);
  const totalPriceWithoutDiscount = useSelector(getTotalPriceWithoutDynamicDiscount)(services);
  const isActiveDiscount = totalPrice !== totalPriceWithoutDiscount;

  const payWithOlioPoints = useSelector(getUseOlioPoints);
  const currentUser = useSelector(getCurrentUser);
  const bookingContext = useSelector(getBookingContext);
  const currentAmount = currentUser && data?.usablePoints;
  let price = totalPrice;
  if (payWithOlioPoints && currentAmount) {
    price = getNewPriceWithPoints(totalPrice, currentAmount);
  }

  const formattedPriceInNOK = formatToNOK(price, true);
  let totalPriceText = '';
  if (bookingContext === BookingContext.UserPage) {
    totalPriceText = `${formattedPriceInNOK}`;
  } else {
    totalPriceText = `${formattedPriceInNOK} betales i salong`;
  }
  return (
    <>
      <SectionListTitle className={styles.title}>Totalt</SectionListTitle>
      <div className={styles.priceWrapper}>
        {isActiveDiscount && (
          <div className={styles.discountWrapper}>
            <BodyBolderText className={styles.servicePrice}>
              {totalPriceWithoutDiscount}kr
            </BodyBolderText>
            <span className={styles.lineThrough} />
          </div>
        )}
        <BodyBolderText>{totalPriceText}</BodyBolderText>
      </div>
    </>
  );
};

export default TotalPrice;
