import { posthog } from 'posthog-js';
import Routes from '../../domain/app/Routes';
import styles from './Navbar.module.css';

type BannerMessage =
  | 'membership'
  | 'christmasGiftcard'
  | 'laser'
  | 'mothersDayGiftcard'
  | 'valentinesGiftcard'
  | 'investment'
  | 'lashes'
  | 'skinCare'
  | 'fiveYearsBirthday'
  | 'constitutionDay'
  | 'summer'
  | 'laserPromotion';

type Props = {
  bannerMessage: BannerMessage;
  showBanner: boolean;
};
const switchMessageWithBannerData = (bannerMessage: BannerMessage) => {
  switch (bannerMessage) {
    case 'membership':
      return {
        emojis: '💕',
        bannerTextMobile: 'Bli medlem – få hver 10.behandling gratis',
        bannerTextDesktop: 'Bli medlem – få hver 10.behandling gratis',
        onClickBanner: () => {
          posthog.capture('Membership clicked');
          window.location.href = Routes.Register();
        },
      };
    case 'christmasGiftcard':
      return {
        emojis: '🎁🎄🎅',
        bannerTextMobile: 'KJØP GAVEKORT',
        bannerTextDesktop: 'Gled en du er glad i - kjøp gavekort',
        onClickBanner: () => {
          posthog.capture('Giftcard christmas clicked');
          window.location.href = Routes.Giftcard();
        },
      };
    case 'mothersDayGiftcard':
      return {
        emojis: '🤍',
        bannerTextMobile: 'Morsdag: -15% på gavekort',
        bannerTextDesktop: '15% rabatt på gavekort til morsdag',
        onClickBanner: () => {
          posthog.capture('Giftcard mothersday clicked');
          window.location.href = Routes.Giftcard();
        },
      };
    case 'valentinesGiftcard':
      return {
        emojis: '💕',
        bannerTextMobile: 'Valentines: -15% på gavekort',
        bannerTextDesktop: '15% rabatt på gavekort til valentines',
        onClickBanner: () => {
          posthog.capture('Giftcard valentines clicked');
          window.location.href = Routes.Giftcard();
        },
      };
    case 'laser':
      return {
        emojis: '☀️',
        bannerTextMobile: 'Vil du bli hårfri til sommeren?',
        bannerTextDesktop:
          'Nå er den beste tiden for laserbehandling – bli hårfri til neste sommer',
        onClickBanner: () => {
          posthog.capture('Laser clicked');
          window.location.href = Routes.Laser();
        },
      };
    case 'lashes':
      return {
        emojis: '🌸',
        bannerTextMobile: 'Bli klar for sommer med fine bryn og vipper',
        bannerTextDesktop: 'Bli klar for sommer med fine bryn og vipper – se våre behandlinger',
        onClickBanner: () => {
          posthog.capture('Bryn & vipper clicked');
          window.location.href = Routes.Treatments();
        },
      };
    case 'investment':
      return {
        emojis: '🚀',
        bannerTextMobile: 'Nå kan du bli medeier i Olio - les mer her',
        bannerTextDesktop:
          'Bli medeier i Olio – invester i fremtidens ledende aktør innen skjønnhet. Les mer her',
        onClickBanner: () => {
          posthog.capture('Investment clicked');
          window.location.href = Routes.Investment();
        },
      };
    case 'skinCare':
      return {
        emojis: '🤍',
        bannerTextMobile: 'Sjekk ut vår helt nye hudpleiemeny',
        bannerTextDesktop: 'Sjekk ut vår nye hudpleiemeny - kjemisk peel for kun 799,-',
      };
    case 'fiveYearsBirthday':
      return {
        emojis: '🎉',
        bannerTextMobile: 'Olio feirer 5 år! Ekstra gode tilbud',
        bannerTextDesktop: 'Olio feirer 5 år! Føl deg ekstra fresh med gode tilbud i hele april',
      };
    case 'constitutionDay':
      return {
        emojis: '🇳🇴',
        bannerTextMobile: 'Vil du bli fresh til 17. mai? Bestill time nå',
        bannerTextDesktop: 'Vil du bli fresh til 17. mai? Bestill time nå',
      };
    case 'summer':
      return {
        emojis: '☀️',
        bannerTextMobile: 'Bli fresh til sommeren hos Olio',
        bannerTextDesktop: 'Bli fresh til sommeren hos Olio',
      };
    case 'laserPromotion':
      return {
        emojis: '',
        bannerTextMobile: 'NÅ: 30% på hårfjerning med laser',
        bannerTextDesktop: 'NÅ: 30% på hårfjerning med laser i september',
      };
    default:
      return {
        emojis: '💕',
        bannerTextMobile: 'Bli medlem – få hver 10.behandling gratis',
        bannerTextDesktop: 'Bli medlem – få hver 10.behandling gratis',
        onClickBanner: () => {
          posthog.capture('Membership clicked');
          window.location.href = Routes.Register();
        },
      };
  }
};

const NavBarBanner = ({ bannerMessage, showBanner }: Props) => {
  const bannerData = switchMessageWithBannerData(bannerMessage);
  if (!showBanner) return null;
  return (
    <div className={styles.giftCardBanner}>
      <div>{bannerData.emojis}</div>
      <a onClick={bannerData.onClickBanner} type="secondary" className={styles.giftCardText}>
        <div className={styles.giftCardTextMobile}>{bannerData.bannerTextMobile}</div>
        <div className={styles.giftCardTextDesktop}>{bannerData.bannerTextDesktop}</div>
      </a>
      <div>{bannerData.emojis}</div>
    </div>
  );
};

export default NavBarBanner;
