import { navigate } from '@reach/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import { isBookingStartInTwentyfourHours } from '../../../common/utils/date.utils';
import Routes from '../../app/Routes';
import { setBookingContext, setBookingId } from '../../booking/forces/actions';
import { getClinicById } from '../../booking/forces/selectors';
import { BookingContext } from '../../booking/forces/types';
import { fetchResources } from '../../booking/sections/resourceSection/forces/actions';
import {
  getResourceById,
  getResources,
} from '../../booking/sections/resourceSection/forces/selectors';
import { getServicesByIdsWithChildrenAndPackageParent } from '../../booking/sections/serviceSection/forces/selectors';
import BookingOverview from '../../booking/sections/summarySection/components/BookingOverview';
import { fetchCurrentUser } from '../forces/actions';
import { getBookingById, getCurrentUser } from '../forces/selectors';
import styles from './Bookinginfo.module.css';

interface Props {
  bookingId: string;
}

const BookingInfo = ({ bookingId }: Props) => {
  const booking = useSelector(getBookingById)(bookingId);
  const currentUser = useSelector(getCurrentUser);
  const resources = useSelector(getResources)(true);
  const resource = useSelector(getResourceById)(booking?.resourceId, true);
  const services = useSelector(getServicesByIdsWithChildrenAndPackageParent)(booking?.serviceIds);
  const clinic = useSelector(getClinicById)(booking.clinicId);
  const time = booking && new Date(booking.time).toString();
  const bookingStartIn24Hours = isBookingStartInTwentyfourHours(time);
  const isOldBooking = new Date(booking.time).getTime() < new Date().getTime();
  const showChangeCancelButton = !isOldBooking && !bookingStartIn24Hours;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      dispatch(fetchCurrentUser());
    }
    if (!resources.length && clinic) {
      dispatch(fetchResources(clinic.id));
    }
    dispatch(setBookingContext(BookingContext.UserPage));
  }, [dispatch, currentUser, clinic]);

  if (!booking) return null;

  const goToUpdateBookingPage = () => {
    dispatch(setBookingId(bookingId));
    navigate(Routes.UpdateBooking({ clinicId: booking.clinicId, bookingId }));
  };

  return (
    <div>
      <BookingOverview
        services={services}
        clinic={clinic}
        resource={resource}
        time={time}
        appliedDiscountPercentage={booking.appliedDiscountPercentage}
      />
      {showChangeCancelButton && (
        <CallToActionButton
          size="large"
          styleType="tertiary"
          onClick={goToUpdateBookingPage}
          className={styles.updateBookingButton}
        >
          Endre/Kanseller timen
        </CallToActionButton>
      )}
    </div>
  );
};

export default BookingInfo;
