import { navigate } from '@reach/router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import CallToActionButton from '../../../../../common/components/callToAction/CallToActionButton';
import FormAlert from '../../../../../common/components/form/FormAlert';
import LoadingIndicator from '../../../../../common/components/loadingIndicator/LoadingIndicator';
import { BodyText, H4 } from '../../../../../common/components/texts';
import Routes from '../../../../app/Routes';
import { getAuthError } from '../../../../auth/forces/selectors';
import LoginPage from '../../../../auth/LoginPage';
import { getCurrentUser } from '../../../../user/forces/selectors';
import { Role } from '../../../../user/forces/types';
import {
  checkIfUserWithMemberRoleExists,
  createBooking,
  deleteBooking,
  resetBookingDelete,
  updateBooking,
  validateAndScrollBookingSections,
} from '../../../forces/actions';
import {
  getBookingError,
  getBookingId,
  getEditMode,
  getGuestInfo,
  getInvalidSections,
  getIsBookingValid,
  getIsLoadingBooking,
  getSelectedClinic,
  getShowLoginOption,
} from '../../../forces/selectors';
import { BookingSectionIDs } from '../../../forces/types';
import styles from './CreateUpdateDeleteBookingButtons.module.css';

const confirmationTextUserMember =
  'Dersom du har betalt med olio-poeng, vil disse bli refundert tilbake til deg om du kansellerer';
const confirmationTextUserGuest = 'Timen vil bli kansellert.';

const CreateUpdateDeleteBookingButtons = () => {
  const [showDeleteBooking, setShowDeleteBooking] = useState(false);
  const bookingError = useSelector(getBookingError);
  const errorMessage = bookingError
    ? 'Det oppsto en feil. Vennligst prøv å velg et annet tidspunkt eller ta kontakt med oss for å få hjelp til å booke timen!'
    : null;
  const isEditMode = useSelector(getEditMode);
  const selectedClinic = useSelector(getSelectedClinic);
  const bookingId = useSelector(getBookingId);
  const isLoading = useSelector(getIsLoadingBooking);
  const authError = useSelector(getAuthError);
  const [showLoginComponent, setShowLoginComponent] = useState(false);
  const isBookingValid = useSelector(getIsBookingValid);
  const showLoginOption = useSelector(getShowLoginOption);
  const [hasCheckedUser, setHasCheckedUser] = useState(false);
  const guestUserInfo = useSelector(getGuestInfo);
  const user = useSelector(getCurrentUser);
  const isUserMember = user && user.role === Role.Member;
  const styleType = isBookingValid ? 'tertiary' : 'secondary';
  const disableBookingButton = !isBookingValid || bookingError ? true : false;
  const loadingText = isEditMode ? 'Oppdaterer timen din' : 'Booker timen din';
  const invalidSections = useSelector(getInvalidSections)();
  const dispatch = useDispatch();

  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIndicator className={styles.loadingContainer} loadingText={loadingText} />
      </div>
    );
  }

  if (bookingError || authError) Scroll.animateScroll.scrollToBottom();

  if (errorMessage) {
    return (
      <div className={styles.deleteContainer}>
        <FormAlert error={errorMessage} />
        <div className={styles.twoButtonWrapper}>
          <CallToActionButton
            size="normal"
            styleType="outlined"
            onClick={() => {
              dispatch(resetBookingDelete());
              setShowDeleteBooking(false);
              navigate(Routes.UserPage());
            }}
          >
            Gå tilbake
          </CallToActionButton>
          {/* <CallToActionButton
          onClick={() => {
            dispatch(resetBookingError());
          }}
        >
          Reset error
        </CallToActionButton> */}
          <CallToActionButton
            size="normal"
            styleType="tertiary"
            onClick={() => navigate(Routes.Typeform())}
          >
            Kontakt oss
          </CallToActionButton>
        </div>
      </div>
    );
  }

  if (showDeleteBooking) {
    return (
      <div className={styles.deleteContainer}>
        <BodyText className={styles.deleteConfirmationText}>
          {isUserMember ? confirmationTextUserMember : confirmationTextUserGuest}
        </BodyText>
        <div className={styles.deleteButtonsContainer}>
          <CallToActionButton
            size="normal"
            styleType="tertiary"
            onClick={() => setShowDeleteBooking(false)}
          >
            Avbryt
          </CallToActionButton>
          <CallToActionButton
            size="normal"
            styleType="tertiary"
            onClick={() => {
              Scroll.animateScroll.scrollToBottom();
              dispatch(deleteBooking(selectedClinic.id, bookingId));
            }}
          >
            Bekreft
          </CallToActionButton>
        </div>
      </div>
    );
  }

  const handleCreateUpdateBooking = () => {
    const shouldCheckIfUserWithMemberRoleExists = !user && !hasCheckedUser;
    if (shouldCheckIfUserWithMemberRoleExists) {
      setHasCheckedUser(true);
      // TODO: This should not dispatch another createBooking. Should return a boolean
      return dispatch(checkIfUserWithMemberRoleExists(guestUserInfo.phoneNumber));
    }
    isEditMode ? dispatch(updateBooking()) : dispatch(createBooking());
    Scroll.animateScroll.scrollToBottom();
  };

  if (showLoginComponent) {
    Scroll.animateScroll.scrollToBottom();
    return (
      <div className={styles.loginPageContainer}>
        <LoginPage createBookingAfterLogin />
        <CallToActionButton onClick={() => dispatch(createBooking())}>
          Book heller som gjest
        </CallToActionButton>
      </div>
    );
  }

  if (showLoginOption) {
    Scroll.animateScroll.scrollToBottom();
    return (
      <div className={styles.loginOptionContainer}>
        <H4 className={styles.text}>
          Du er registrert som medlem hos oss. Ønsker du å logge inn først?
        </H4>
        <div className={styles.loginOptions}>
          <CallToActionButton
            onClick={() => setShowLoginComponent(true)}
            className={styles.loginOptionButton}
          >
            Ja. Logg inn
          </CallToActionButton>
          <CallToActionButton
            onClick={() => dispatch(createBooking({ isMemberBookedAsGuest: true }))}
            className={styles.loginOptionButton}
          >
            Nei. Book som gjest
          </CallToActionButton>
        </div>
      </div>
    );
  }

  return (
    <Scroll.Element name={BookingSectionIDs.CreateUpdateDeleteBooking}>
      <div className={styles.updateAndDeleteButtonsContainer}>
        <CallToActionButton
          size="large"
          styleType={styleType}
          disabled={disableBookingButton}
          onClick={handleCreateUpdateBooking}
          className={styles.createOrUpdateBookingButton}
        >
          {isEditMode ? 'Oppdater' : 'Book'}
        </CallToActionButton>
        {isEditMode && (
          <CallToActionButton
            className={styles.deleteButtonText}
            onClick={() => setShowDeleteBooking(true)}
          >
            Kanseller bookingen
          </CallToActionButton>
        )}
        <div
          className={styles.invalidSecitons}
          onClick={() => dispatch(validateAndScrollBookingSections())}
        >
          {invalidSections.map((invalidSection) => (
            <BodyText key={invalidSection} className={styles.invalidText}>
              * {invalidSection}
            </BodyText>
          ))}
        </div>
      </div>
    </Scroll.Element>
  );
};

export default CreateUpdateDeleteBookingButtons;
